@import 'src/styles/components_importer';

.root {
  color: $red;
  padding-top: 7px;
  padding-bottom: 10px;
  display: block;
  line-height: 1;
  font-size: 12px;
  margin-bottom: 10px;
}
