@import 'src/styles/components_importer';

.input {
  display: inline-flex;
  align-items: center;
  background-color: $white;
  width: 100%;
  height: 51px;
  font-size: inherit;
  padding-left: 5px;
  color: $true-black;
  border: 1px solid $primary-gray-dark;
  border-radius: 0px;
  -webkit-appearance: none;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;

  &.error {
    margin-bottom: 0px;
    border-color: $red;
    color: $red;
  }
}
