$font-family-toyota-icons: 'toyota-bat-icons';

//BAR ICON
@font-face {
  font-family: $font-family-toyota-icons;
  src: url('../../fonts/icons/toyota-bat-icons-082615.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//ICON FONTS:
[class^='icon-'],
[class*=' icon-'],
.icon-info,
.sliderBtnPrev,
.sliderBtnNext {
  font-family: $font-family-toyota-icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: '\71';
}

.icon-offers:before,
.icon-find-offers:before {
  content: '\77';
}

.icon-vehicles:before,
.icon-find-vehicles:before {
  content: '\65';
}

.icon-dealer-pin:before,
.icon-find-a-dealer:before {
  content: '\72';
}

.icon-heart-off:before {
  content: '\74';
}

.icon-heart-on:before {
  content: '\79';
}

.icon-heart-plus:before {
  content: '\75';
}

.icon-search:before {
  content: '\69';
}

.icon-inventory:before,
.icon-search-2:before {
  content: '\44';
}

.icon-pencil:before {
  content: '\6f';
}

.icon-build-and-price:before {
  content: '\39';
}

.icon-parts-and-service:before {
  content: '\61';
}

.icon-compare-offers:before {
  content: '\73';
}

.icon-competitive-comparison:before {
  content: '\64';
}

.icon-toyota-mobility:before {
  content: '\66';
}

.icon-deal-builder:before {
  content: '\67';
}

.icon-payment-calculator:before {
  content: '\68';
}

.icon-payment-estimator:before {
  content: '\68';
}

.icon-trade-in-value:before {
  content: '\6a';
}

.icon-apply-for-credit:before {
  content: '\6b';
}

.icon-take-a-test-drive:before {
  content: '\3b';
}

.icon-contact-dealer:before {
  content: '\27';
}

.icon-share:before {
  content: '\7a';
}

.icon-print:before {
  content: '\78';
}

.icon-alarm:before {
  content: '\63';
}

.icon-grid:before {
  content: '\76';
}

.icon-list:before {
  content: '\62';
}

.icon-arrow-up:before {
  content: '\6e';
}

.icon-arrow-down:before {
  content: '\6d';
}

.icon-location:before {
  content: '\2c';
}

.icon-dot:before {
  content: '\2e';
}

.icon-linkout:before {
  content: '\2f';
}

.icon-linkout-after:after {
  content: '\2f';
}

.icon-info:before {
  content: '\31';
}

.icon-dealer-site:before {
  content: '\32';
}

.icon-chat:before {
  content: '\33';
}

.icon-trash:before {
  content: '\34';
}

.icon-calendar:before {
  content: '\35';
}

.icon-directions:before {
  content: '\36';
}

.icon-arrow-l:before {
  content: '\3c';
}

.icon-arrow-r:before {
  content: '\3e';
}

.icon-bullet:before {
  content: '\2a';
}

.icon-close:before {
  content: '\37';
}

.icon-ebrochure-vehicle:after {
  content: '\38';
}

.icon-featured:after,
.icon-star:after {
  content: '\47';
}

.icon-location2:after {
  content: '\48';
}

.icon-dollar:after {
  content: '\46';
}

.icon-request-a-quote:after,
.icon-dollar-circled:after {
  content: '\41';
  font-size: 27px;
}

.icon-cancel:after {
  content: '\43';
}

.icon-globe:before {
  content: '\45';
}

.icon-crosshair:before {
  content: '\42';
}

.icon-clock:before {
  content: '\49';
}

.icon-phone:before {
  content: '\4a';
}

.icon-arrow:before {
  content: '\6d';
}
