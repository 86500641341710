@import 'src/styles/components_importer';

.modal {
  width: 750px;
}

@include breakpoint-tablet-only() {
  .modal {
    width: 600px;
  }
}

@include breakpoint-mobile-only() {
  .modal {
    width: 100%;
    height: 100%;
  }

  .modalContent::before {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
  }
}

.modalContent {
  padding: 40px 50px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modalHeader {
  display: flex;
  justify-content: space-between;

  .closeButton {
    @include fontSize(18px);

    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    width: 40px;
    height: 40px;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid $gray;
    border-radius: 20px;
  }
  .title {
    text-align: center;
    @include fontSize(34px);
    font-family: $font-family-semibold;
  }
}

.list {
  list-style: none;
}

.listElement {
  padding: 10px 0;
  line-height: 1.5;
  display: table;
  width: 100%;

  .disclosureId {
    padding-right: 20px;
  }
}

.customDialog {
  padding: 20px;
}
