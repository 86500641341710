@import 'src/styles/components_importer';

@include print(carretContainer);

.root {
  position: relative;
  .button {
    height: 48px;
    background-color: white;
    padding: 6px 12px;
    @include fontSize(12px);
    font-family: $font-family-semibold;
    color: $black;
    text-align: left;
    width: 100%;
    border-radius: 3px;
    border: 1px solid $black;

    .carretDown {
      @extend %carret;
      right: 16px;
      transform: translateY(-50%) rotate(45deg);
    }

    .carretUp {
      @extend %carret;
      right: 16px;
      transform: translateY(calc(-50% + 5px)) rotate(225deg);
    }
  }

  .labelWrapper {
    @include fontSize(14px);
    position: absolute;
    transform: translateY(-50%);
    left: 10px;
    padding: 0 4px;
    z-index: 99;
    background: $white;
    top: 0;
  }

  .hasSup {
    padding-right: 10px;
  }

  .labelSup {
    @extend %disclaimer-number;
    right: 3px;
  }

  .menu {
    position: absolute;
    border-radius: 0;
    border: 0;
    padding: 0;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    width: 100%;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }

  .menuItem {
    @include fontSize(16px);
    font-family: $font-family-semibold;
    display: block;
    padding: 5px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: $black;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

@include breakpoint-tablet {
  .root {
    .button {
      height: 56px;
      @include fontSize(14px);
    }
  }

  .labelWrapper {
    @include fontSize(12px);
  }
}

@media print {
  .root {
    .button {
      padding: 0;
      @include fontSize(20px);
    }
  }
}
