@import 'src/styles/components_importer';

:global(.whiteBg) {
  background: $white;
}

.estimatorSectionWrapper {
  display: table;
  margin: 0 auto;
  padding: 0 15px;
}

.containerWrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0 8px 0;
  row-gap: 40px;
  max-width: 624px;
}

@include breakpoint-tablet {
  .estimatorSectionWrapper {
    padding: 0 24px;
  }

  .containerWrapper {
    justify-content: space-between;
    flex-direction: row;
    padding: 12px 0 8px 0;
    margin: 0;
    row-gap: 0;
    column-gap: 48px;
  }
}
