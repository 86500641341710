@import 'src/styles/components_importer';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms';

// Bootstrap form control overrides
.input {
  @extend .form-control;
  @include fontSize(16px);
  border-color: none;
  border: 0;
  border-radius: 0;
  font-family: $font-family-semibold;
  font-weight: 500;
  color: $black;
  box-shadow: none;

  &.invalid {
    border: 1px solid #d71921;
    color: #d71921;
  }

  &:focus {
    box-shadow: none;
    outline: 1px solid $color-blue;
  }
}

@include breakpoint-tablet() {
  .input {
    display: inline-block;
    width: auto; // Prevent labels from stacking above inputs in `.form-group`
    vertical-align: middle;
  }
}

/* Change autocomplete background (chrome) */
input:-webkit-autofill {
  background-color: white !important;
}

/* Overrides bootstrap group addon */
// .input-group-addon {
//   @include fontSize(13px);
//   font-weight: 500;
//   color: $black;
//   text-align: center;
//   background-color: white;
//   border: none;
//   border-radius: 0;
//   padding-left: 5px;
//   padding-right: 2px;
// }

// // Add currency icon($) to input text box
// .input-currency {
//   position: relative;
//   input[type='text'] {
//     padding-left: 25px;
//   }

//   &:after {
//     content: '$';
//     position: absolute;
//     top: 11px;
//     left: 18px;
//   }
// }
