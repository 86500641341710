@import 'src/styles/components_importer';

.dealerWrapper {
  margin-top: 20px;

  label {
    display: block;
  }
}

.editZipWrapper {
  float: right;
  position: relative;
  bottom: 4px;

  .edit {
    margin-left: 5px;
    transform: rotate(70deg);
    display: inline-block;
    @include fontSize(18px);
    cursor: pointer;
  }

  .zipcode {
    -webkit-appearance: none;
    outline: none;
    border: none;
    text-align: end;
    color: $primary-red;

    &.open {
      padding: 8px;
      width: 80px;
      color: $true-black;
    }
  }
}

.zipCode {
  width: 101px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.dealerList {
  margin-top: 5px;
  .dealerDetailsContainer {
    display: inline-flex;
    align-items: flex-start;
    height: 50px;
    padding-top: 5px;
  }

  .dealerDetails {
    line-height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: inherit;
  }

  .dealerName {
    @include fontSize(15px);
    display: inline-flex;
    padding-left: 5px;
  }

  .radio {
    position: relative;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    border: 1px solid $primary-gray-dark;
    background: $white;
    margin: 0;
    border-radius: 55%;
    top: 10px;

    &::before {
      content: ' ';
      position: absolute;
      top: 75%;
      right: 75%;
      bottom: 75%;
      left: 75%;
      transition: all 0.1s;
      background: $white;
    }

    &:checked {
      background: $primary-red;
      border: none;

      &::before {
        top: 8px;
        right: 8px;
        bottom: 8px;
        left: 8px;
        border-radius: 55%;
      }
    }
  }

  .distance {
    width: 94px;
    color: $primary-gray;
    @include fontSize(10px);
    padding-left: 5px;
  }

  .address {
    width: 275px;
    display: inline-flex;
    padding-left: 5px;
    @include fontSize(12px);
  }
}

.viewMore {
  color: $primary-red;
  margin-top: 10px;
  @include fontSize(14px);
  cursor: pointer;

  &::after {
    content: '';
    position: relative;
    left: 8px;
    bottom: 2px;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid $primary-red;
    border-right: 2px solid $primary-red;
    transform: rotate(134deg);
  }
}
