$font-family-glyphicons: 'Glyphicons Halflings';

@mixin glyphicon {
  position: relative;
  left: 1px;
  display: inline-block;
  font-family: $font-family-glyphicons; // generic fallback added for SonarQube
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
