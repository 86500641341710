.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  background-color: white;
}

.modal {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -65%);
  display: flex;
  padding: 20px;
  justify-content: center;
  z-index: 10001;
  background-color: #333;
  color: #f5f5f5;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
}
