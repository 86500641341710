@import 'src/styles/components_importer';

.root {
  clear: both;
}

.title {
  font-family: $font-family-semibold;
  padding: 16px 0 10px 0;
  @include fontSize(20px);
  line-height: 28px;
}

@include breakpoint-tablet {
  .title {
    @include fontSize(18px);
    line-height: 18px;
    padding: 16px 0 15px 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  list-style: none;
  position: relative;
  visibility: visible;
  width: 100%;

  &.tablet {
    padding-left: 15px;
    padding-right: 15px;
  }

  .offerRow {
    position: relative;
    padding: 4px;
    display: inline-block;
  }
}

.customDialog {
  width: 100%;
}

@include breakpoint-desktop {
  .customDialog {
    max-width: 800px;
  }
}
