@import 'src/styles/components_importer';

.statusBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  max-height: 0;
  padding: 0px 40px;
  z-index: 2000;
  background-color: #d71921;
  transition: max-height 0.5s;
  font-size: 14px;
  font-size: 0.875rem;
  font-family: $font-family-md;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  text-align: center;

  &.active {
    height: auto;
    max-height: 90px;
    padding: 10px 40px;
  }
}

.closeButton {
  display: none;
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 16px;
  background: none;
  border: none;

  &.buttonActive {
    display: inline-block;
  }

  &:before {
    content: '\00d7';
    color: white;
  }
}
