/*
 * Root SASS file
 */

@import 'variables';
@import 'mixins';
@import 'reset';
@import 'layout/defaults';

// removes ajax-loader.gif error
.slick-list {
  .slick-loading & {
    background: #fff;
  }
}

// FONTS
@import 'fonts/toyota-icons';
@import 'fonts/toyota-type';
@import 'fonts/glyphicons';
@import 'fonts/helvetica-neue';

// PRELOAD
@import 'preload';
