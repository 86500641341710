@import 'src/styles/components_importer';

.offerDetailsModalList {
  margin-left: 0;
  margin-bottom: 30px;
  li {
    a {
      color: $red;
      text-decoration: none;
      padding-left: 25px;
      padding-bottom: 8px;
      line-height: 1.43;
      @include fontSize(14px);
      display: inline-block;
    }
    p {
      margin: 0;
      text-decoration: none;
      padding-left: 25px;
      padding-bottom: 8px;
      line-height: 1.43;
      @include fontSize(14px);
      display: inline-block;
    }
    button {
      color: #d71921;
      text-decoration: none;
      margin-left: 25px;
      margin-bottom: 8px;
      line-height: 1.43;
      font-size: 14px;
      font-size: 0.875rem;
      display: inline-block;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #333;
    }
  }
}
