@font-face {
  font-family: 'ToyotaTypeRegular';
  src: url('../../fonts/toyota-type/ToyotaType-Regular.eot')
      format('embedded-opentype'),
    url('../../fonts/toyota-type/ToyotaType-Regular.ttf') format('truetype'),
    url('../../fonts/toyota-type/ToyotaType-Regular.woff') format('woff'),
    url('../../fonts/toyota-type/ToyotaType-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ToyotaTypeSemibold';
  src: url('../../fonts/toyota-type/ToyotaType-Semibold.eot')
      format('embedded-opentype'),
    url('../../fonts/toyota-type/ToyotaType-Semibold.ttf') format('truetype'),
    url('../../fonts/toyota-type/ToyotaType-Semibold.woff') format('woff'),
    url('../../fonts/toyota-type/ToyotaType-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
