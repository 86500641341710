@import 'src/styles/components_importer';

.notificationWrapper {
  background-color: $background-color;
  padding: 24px;
  font-family: $font-family-semibold;

  .title {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .text {
    font-family: $default-font-family;
    padding-top: 8px;
    font-size: 14px;
    line-height: 22px;

    button {
      cursor: pointer;
      font-family: $font-family-semibold;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
    }
  }
}
