$icon-font-path: '../../fonts/glyphicons/';

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('#{$icon-font-path}glyphicons-halflings-regular.eot')
      format('embedded-opentype'),
    url('#{$icon-font-path}glyphicons-halflings-regular.ttf') format('truetype'),
    url('#{$icon-font-path}glyphicons-halflings-regular.woff') format('woff'),
    url('#{$icon-font-path}glyphicons-halflings-regular.woff2') format('woff2'),
    url('#{$icon-font-path}glyphicons-halflings-regular.svg') format('svg');
}
