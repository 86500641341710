@import 'src/styles/components_importer';

.modalWrapper {
  padding: 32px 16px;
  width: 340px;
}

.title {
  @include fontSize(20px);
  line-height: 28px;
  font-family: $font-family-semibold;
  padding-bottom: 8px;
}

.description {
  @include fontSize(14px);
  line-height: 24px;
  padding-bottom: 16px;
}

.quizWrapper {
  @include fontSize(12px);
  line-height: 16px;
  color: $primary-red;
  font-family: $font-family-semibold;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus {
    outline: 1px solid $color-blue;
    outline-offset: 1px;
  }
}

.modal {
  position: absolute;
  right: 0;
  top: 40px !important;
  padding: 0;
  height: auto;
}

.closeButton {
  @include fontSize(18px);

  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  justify-content: center;
  align-items: center;
  color: $black;
  width: 40px;
  height: 40px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid $gray;
  border-radius: 20px;
  margin: 10px;
}
