@import 'src/styles/components_importer';

.modal {
  width: 100%;
  height: 100%;
}

@include breakpoint-desktop {
  width: 100%;
  .modal {
    max-width: 750px;
  }
}

.customDialog {
  width: 100%;
  padding: 0;
  top: 0;
}

@include breakpoint-desktop {
  .customDialog {
    padding: 5px;
    top: 30px;
    max-width: 750px;
  }
}

@include breakpoint-mobile-only() {
  .modalContent::before {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
  }
}

.modalContent {
  padding: 5px;
}

@include breakpoint-tablet() {
  .modalContent {
    padding: 40px 50px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  .closeButton {
    @include fontSize(18px);

    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    width: 40px;
    height: 40px;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid $gray;
    border-radius: 20px;
  }
  .title {
    text-align: center;
    @include fontSize(34px);
    font-family: $font-family-semibold;
  }
}
