@import 'src/styles/components_importer';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons';

@include print(button);

.button {
  @extend .btn;
  font-family: $font-family-semibold;
  border-radius: 0;
  border: none;

  @include fontSize(13px);
  $padding: 8px;
  padding-top: $padding;
  padding-bottom: $padding;
  vertical-align: bottom;
  margin-top: 5px;

  margin-left: 4px;

  &:hover,
  &:active {
    outline: 0 !important;
    box-shadow: none;
    border: none;
  }
  &:focus {
    outline: 1px solid $color-blue;
    outline-offset: 1px;
  }
  &.using-mouse {
    &:focus {
      outline: 0;
    }
  }
}

/* Bootstrap override for default button */
.button-red {
  @include button-variant(white, $red, transparent);
  &:focus {
    background-color: $red;
  }
}

.button-gray {
  @include button-variant($black, $slate, transparent);
  &:focus,
  &:active {
    background-color: $slate;
  }

  &:focus,
  &:active,
  &:visited {
    border-bottom: 2px solid #d2d5da;
  }

  &:hover {
    border-bottom: 2px solid $gray;
  }
}

.button-white {
  @include button-variant($black, white, transparent);
}
