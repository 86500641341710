/*
 * Global SASS vars
 */

// LAYOUT
$outer-container-width-desktop: 800px;

// COLORS

$white: white;
$blue: #0979cf;
$red: #d71921;
$gray: #9f9f9f;
$gray-light: #d3d3d3;
$gray-dark: #58595b;
$offer-blue: #428bc6;
$slate: #edf0f5;
$black: #333;
$black-dark: #400;
$color-blue: #3a6bc6;
$true-black: black;
$primary-gray-dark: #d8d8d8;
$primary-gray-light: #eeeeee;
$primary-red: #eb0a1e;
$primary-red-dark: #c00000;
$primary-gray: #9b9b9b;
$background-color: #f6f6f6;

// BOOTSTRAP TOOLTIP
$tooltip-arrow-color: rgba(0, 0, 0, 0.7);

// FONTS
$font-family-md: 'ToyotaTypeRegular', 'Helvetica', 'Arial', 'Lucida Grande',
  'sans-serif';
$font-family-semibold: 'ToyotaTypeSemibold', 'Helvetica', 'Arial',
  'Lucida Grande', 'sans-serif';

// FONT DEFAULTS

$default-font-size: 100%;
$default-font-family: $font-family-md;
$default-font-color: $black;
$default-font-weight: 300;

// PIXELS DEFINED BY EMS (Relative to default font size)
$font-size-12px: 0.75em;
$font-size-13px: 0.8125em;
$font-size-30px: 1.875em;
$font-size-32px: 2em;
$font-size-36px: 2.25em;

// BREAKPOINTS
$tablet-breakpoint: 768px;
$desktop-breakpoint: 960px;
$screen-md: $desktop-breakpoint; // override bootstrap desktop

$mobile-only: unquote('(min-width: 0) and (max-width: 767px)');
$tablet-only: unquote('(min-width: 768px) and (max-width: 959px)');
$tablet: unquote('(min-width: 768px)');
$desktop: unquote('(min-width: 960px)');

// PADDING
$padding-top: 20px;
$padding-top-mobile: 10px;
$padding-left: 50px;
$padding-left-mobile: 0px;
$padding-right: 50px;
$padding-right-mobile: 15px;

$modal-index: 1000;
$modal-overlay-index: 999;
