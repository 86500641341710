.pe-caro .slick-dots {
  bottom: -30px;
}
.pe-caro .slick-dots li button {
  padding: 3px;
  width: 2px;
  height: 2px;
  border: 0;
  border-radius: 50%;
  background-color: white;
  margin: 0 auto;
}
.pe-caro .slick-dots li button:before {
  color: transparent;
  opacity: 1;
}
.pe-caro .slick-dots li.slick-active button {
  border: 2px solid #257bdf;
  background-color: transparent;
}
.pe-caro .slick-list {
  min-height: 50px;
}

.pe-caro .slick-track {
  margin-left: initial;
  margin-right: initial;
}
