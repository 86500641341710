@import 'src/styles/components_importer';
@import 'src/styles/layout/defaults';

.ui-state-error {
  color: #eb0a1e;
  padding-top: 7px;
  padding-bottom: 10px;
  display: block;
  line-height: 1;
  font-size: 12px;
}

.zipBody {
  @extend %wrapper;
  @include outer-container(90%);
}

.zipContainer {
  margin-top: 20px;
  text-align: center;
  padding-bottom: 160px;
}

.zipLabel {
  @include fontSize(13px);
  margin-bottom: 0px;
  font-weight: 300;
}

.zipLabelText {
  @include fontSize(13px);
  display: inline-block;
  margin-bottom: 8px;
  font-weight: $default-font-weight; // override bootstrap
}

.formGroup {
  margin-bottom: 15px;
}

@include breakpoint-tablet() {
  .formGroup {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

// breakpoints
@include breakpoint-desktop() {
  .zipBody {
    @include outer-container(800px);
  }

  .zipContainer {
    margin-top: 60px;
  }

  .zipLabelText {
    margin-bottom: 0;
  }
}
