@import 'src/styles/components_importer';

.payment {
  position: relative;
  background-color: inherit;
  font-family: $font-family-semibold;
  padding-top: 13px;
  flex-basis: 50%;

  .disclaimerCta {
    @extend %disclaimer-number;
  }
}

@include breakpoint-tablet {
  .payment {
    padding-top: 0;
  }
}

.firstPaymentDetailsWrapper {
  @include fontSize(32px);
  line-height: 40px;
  padding: 10px 0;
  display: flex;
  align-items: stretch;

  .label {
    @include fontSize(10px);
    line-height: 24px;
    position: relative;
    width: fit-content;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div:not(:last-child) {
    border-right: 1px solid #d3d3d3;
    margin-right: 16px;
    padding-right: 16px;
  }
}

@include breakpoint-tablet {
  .firstPaymentDetailsWrapper {
    padding: 16px 0 8px 0;
    @include fontSize(24px);
    line-height: 32px;
  }
}

.secondPaymentDetailsWrapper {
  @include fontSize(24px);
  line-height: 32px;
  display: flex;
  padding-bottom: 16px;

  .sub {
    @include fontSize(10px);
    line-height: 24px;
    position: relative;
  }
}

.paymentElements {
  @include fontSize(12px);
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  margin-top: 0;

  p {
    position: relative;
    max-width: 50%;
  }
}

@include breakpoint-tablet {
  .paymentElements {
    line-height: 16px;
  }
}

.paymentDetails {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.priceWrapper {
  @include fontSize(12px);
  line-height: 18px;
  padding-top: 15px;
  align-items: center;

  p {
    max-width: 70%;
    position: relative;
  }
}

@include breakpoint-tablet {
  .priceWrapper {
    padding-top: 0;
    @include fontSize(16px);
    line-height: 24px;
  }
}

.disclaimer {
  @include fontSize(10px);
  line-height: 18px;
  font-family: $font-family-md;
  color: $gray-dark;
  padding-bottom: 10px;
}

@include breakpoint-tablet {
  .disclaimer {
    padding-bottom: 16px;
  }
}

.ctaWrapper {
  padding-top: 16px;
}

.notificationWrapper {
  padding-top: 16px;
}
