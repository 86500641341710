@import 'src/styles/components_importer';

.overlay {
  opacity: 0.5;
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

.modal {
  position: absolute;
  top: 60px;
  right: 50%;
  transform: translate(50%, -1000px);
  width: 600px;
  z-index: 1050;
  visibility: hidden;

  &.open {
    visibility: visible;
    transform: translate(50%, -30px);
    transition: transform 0.3s ease-out;
  }
}

@include breakpoint-mobile-only() {
  .modal {
    width: 100%;
    &.open {
      transform: translate(50%, -60px);
    }
  }

  .modalContent::before {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
    background: #d71921;
  }
}

.modalBody {
  padding: 15px;
}

.modalContent {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  outline: 0;
}

.modalHeader {
  padding: 15px;
  .closeModal {
    text-align: right;
  }
  .closeButton {
    margin-top: -2px;
    @include fontSize(24px);
    font-weight: 700;
    line-height: 1;
    color: $red;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }
  .closeSpan::before {
    content: '\00D7';
  }
  .title {
    text-align: center;
    @include fontSize(34px);
    font-family: $font-family-semibold;
  }
}

.description {
  font-size: 14px;
  font-size: 0.875rem;
  color: $gray-dark;
  text-align: center;
  line-height: 1.4;
  margin: 10px 0 25px 0;
}

.buttonWrapper {
  display: flex;
  .actionButton {
    border-style: none;
    flex: 1;
    background-color: $red;
    color: white;
    font-family: $font-family-semibold;
    padding: 11px 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
