@import 'src/styles/components_importer';

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: calc(0.375em - 1px) 1.5em;
  height: 56px;
  appearance: none;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1.5;
  cursor: pointer;
  background: none;
  text-decoration: none;
  box-sizing: border-box;
}

.redArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  background-color: $white;
  min-height: 56px;
  border: 1px solid $primary-gray-light;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 0 16px;
  cursor: pointer;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    right: 20px;
    display: inline-flex;
    width: 11px;
    height: 11px;
    border-top: 2px solid $primary-red;
    border-right: 2px solid $primary-red;
    transform: rotate(45deg);
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.2s cubic-bezier(0.3, 0.38, 0.15, 1);
  }
}

.defaultCta {
  background: $primary-red;
  border: 1px solid $primary-red;
  color: $white;
  //padding-right: 32px;
  font-size: 15px;
  font-weight: 600;
  outline: none;

  &:disabled {
    color: $primary-gray;
    border: 1px solid $primary-gray-dark;
    background: none;
    cursor: default;
  }
  &:focus {
    background: $primary-red-dark;
  }
}

.toggleArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  min-height: 56px;
  border: none;
  padding: 0 24px 0 16px;
  font-family: $default-font-family;
  cursor: pointer;
  outline: none;

  &::after {
    content: '';
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 8px;
    border-right: 2px solid $primary-red;
    border-bottom: 2px solid $primary-red;
    transform: rotate(45deg);
    transition: transform 0.2s cubic-bezier(0.3, 0.38, 0.15, 1);
  }

  &.toggled::after {
    transform: rotate(-135deg);
    margin: 4px 0 0 8px;
  }
}

.fullWidth {
  width: 100%;
}

.withUnderline {
  @include fontSize(14px);
  line-height: 24px;
  font-family: $font-family-semibold;
  border-radius: 0;
  cursor: pointer;
  border: 0px;
  padding: 0;
  height: 18px;

  &:hover {
    color: darken($red, 10%);
  }

  &.inactive {
    cursor: pointer;
    color: $gray;
    background: transparent;
    border: 0;

    &::after {
      content: none;
    }
  }
  color: $primary-red;
}

.withRedUnderline {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $primary-red;
    bottom: -2px;
  }
}

.withBlackUnderline {
  color: $black;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $black;
    bottom: -2px;
  }
}
