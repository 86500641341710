@import 'src/styles/components_importer';

.success {
  text-align: center;
  padding: 32px;

  h3 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    @include fontSize(32px);
  }

  p {
    margin: 0;
  }

  button {
    margin: 32px 0 0 0;
  }
}
