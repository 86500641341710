body {
  font-family: $default-font-family;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  color: $default-font-color;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.overlayIsOpen {
    overflow: auto;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background: $slate;
}

%wrapper {
  padding-top: $padding-top-mobile;
  padding-left: $padding-left-mobile;
  @include media($tablet) {
    padding-top: $padding-top;
    padding-left: $padding-left-mobile;
  }
}

strong {
  font-weight: bold;
}

input,
textarea {
  font-family: $default-font-family;
}
