//LIGHT
@font-face {
  font-family: 'Helvetica Neue Lt';
  src: url('/fonts/helvetica/HelvNeueLight/HelveticaNeueW01-45Light.EOT')
      format('embedded-opentype'),
    url('/fonts/helvetica/HelvNeueLight/HelveticaNeueW01-45Light.ttf')
      format('truetype'),
    url('/fonts/helvetica/HelvNeueLight/HelveticaNeueW01-45Light.woff')
      format('woff'),
    url('/fonts/helvetica/HelvNeueLight/HelveticaNeueW01-45Light.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

//MEDIUM
@font-face {
  font-family: 'Helvetica Neue Med';
  src: url('/fonts/helvetica/HelvNeueMed/HelveticaNeueW01-65Medium.EOT')
      format('embedded-opentype'),
    url('/fonts/helvetica/HelvNeueMed/HelveticaNeueW01-65Medium.ttf')
      format('truetype'),
    url('/fonts/helvetica/HelvNeueMed/HelveticaNeueW01-65Medium.woff')
      format('woff'),
    url('/fonts/helvetica/HelvNeueMed/HelveticaNeueW01-65Medium.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

//BOLD
@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('/fonts/helvetica/HelvNeueBold/HelveticaNeueW01-75Bold.EOT')
      format('embedded-opentype'),
    url('/fonts/helvetica/HelvNeueBold/HelveticaNeueW01-75Bold.ttf')
      format('truetype'),
    url('/fonts/helvetica/HelvNeueBold/HelveticaNeueW01-75Bold.woff')
      format('woff'),
    url('/fonts/helvetica/HelvNeueBold/HelveticaNeueW01-75Bold.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
