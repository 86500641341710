@import 'src/styles/components_importer';

.offerInfo {
  display: inline-block;
  width: 50%;
  min-height: 1px;
  font-size: 32px;
  text-align: center;

  &:first-child {
    border-right: 1px solid #ccc;
  }

  div {
    font-size: 14px;
    color: $black;
    display: block;
    text-transform: uppercase;
    font-weight: 300;
  }
}

.offerInfoLease {
  display: inline-block;
  width: 33%;
  min-height: 1px;
  font-size: 32px;
  text-align: center;

  &:first-child,
  &:nth-child(2) {
    border-right: 1px solid #ccc;
  }

  div {
    font-size: 14px;
    color: $black;
    display: block;
    font-weight: 300;
  }
}

.offerInfoFullWidth {
  text-align: center;

  .cashBack {
    font-size: 3.25rem;

    span {
      color: $gray-dark;
      margin-top: 5px;
      font-size: 1.5625rem;
      display: inline-block;
      margin-left: 10px;
    }
  }
}
