@import 'src/styles/components_importer';

.inputWrapper {
  position: relative;

  .errorMessage {
    @include fontSize(12px);
    line-height: 16px;
    color: $primary-red;
  }
}

.input {
  @include fontSize(12px);
  height: 48px;
  font-family: $font-family-semibold;
  border-radius: 3px;
  border: 1px solid $black;
  font-weight: 500;
  color: #333;
  box-shadow: none;
  padding: 6px 12px;
  width: 100%;
  background-color: #fff;
  background-image: none;
  &.disabled {
    font-family: $font-family-semibold;
  }
}

.hasError {
  border: 1px solid $primary-red;
}

@include breakpoint-tablet {
  .input {
    @include fontSize(14px);
    height: 56px;
  }
}

.labelWrapper {
  @include fontSize(14px);
  background-color: $white;
  font-family: $font-family-semibold;
  font-weight: 500;
  position: absolute;
  transform: translateY(-50%);
  left: 10px;
  padding: 0 4px;
  z-index: 99;
}

.hasSup {
  padding-right: 10px;
}

.labelItem {
  background-color: $white;
  &:last-of-type {
    white-space: nowrap;
  }
}

.labelSup {
  @extend %disclaimer-number;
  right: 3px;
}

@include breakpoint-tablet {
  .labelWrapper {
    @include fontSize(12px);
  }
}
