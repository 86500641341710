@import 'src/styles/components_importer';

.label {
  @include fontSize(12px);
  position: relative;
  display: block;
  line-height: 16px;
  margin-bottom: 0;
  font-weight: 300;
  max-width: 100%;

  .input {
    display: none;
    margin: 4px 0 0;
    line-height: normal;
  }

  .checkMark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid $gray;
    margin-right: 10px;
    cursor: pointer;

    &.disabled {
      background-color: #d3d3d3;
      cursor: default;
    }
    &::before {
      @include glyphicon;
      content: '\e013';
      @include fontSize(14px);
      font-size: 12px;
      visibility: hidden;
      left: 0;
    }
    &.active::before {
      visibility: visible;
    }
  }

  .checkBoxLabel {
    display: inline-block;
    margin-left: 25px;
    .labelText {
      @include fontSize(14px);
      position: relative;
      line-height: 24px;
      font-family: $font-family-semibold;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $gray-dark;
        left: 0;
        bottom: 4px;
      }
    }

    .button {
      @include fontSize(12px);
      color: #58595b;
      cursor: pointer;
      border: 0;
      display: inline-block;
      background-color: none;
    }
  }
}
