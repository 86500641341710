/*
 * Container Mixins
 */

//  Adds max-width and center aligns the element
@mixin outer-container($width...) {
  @include container-fixed();
  max-width: $width;
}

// Generate the extra small columns
@mixin make-xs-column-own($columns, $gutter: 30px) {
  position: relative;
  float: left;
  width: percentage(($columns / 12));
  min-height: 1px;
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);
}
