@import 'src/styles/components_importer';

@include print(root);

.root {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include breakpoint-tablet {
  .root {
    justify-content: flex-end;
  }
}

.buttons {
  position: relative;
  display: flex;
  column-gap: 24px;
  width: 100%;
  list-style: none;

  .button {
    margin-left: 0;
    @include fontSize(12px);
    font-family: $font-family-semibold;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: $gray;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    border: 0px;

    padding: 0;

    opacity: 1;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.65;
      &:hover {
        background-color: $gray-dark;
      }
    }

    &.active {
      color: $primary-red;
      border-bottom: 2px solid $primary-red;

      &:hover {
        color: darken($red, 10%);
      }
    }
  }
}

@include breakpoint-desktop {
  .root {
    width: 16.6666666667%;
    margin-top: 1px;
    padding-left: 0;
    padding-right: 0;
  }
  .toggle {
    margin-top: 24px;
  }
  .wrapper {
    margin: 0;
  }
}

.iconInfo {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-top: 4px;
}
