/*
Font size mixins
*/

$pxPerRem: 16px;

@mixin fontSize($size) {
  font-size: $size;
  font-size: #{calc($size/$pxPerRem)}rem;
}
