@import 'src/styles/components_importer';

.disclaimerWrapper {
  @include fontSize(10px);
  line-height: 18px;
  font-family: $font-family-md;
  color: $gray-dark;
  padding-top: 16px;
  text-align: center;
  max-width: 624px;
}
