@import 'src/styles/components_importer';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 35px;

  .title {
    @include fontSize(20px);
    line-height: 28px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 16px;
  }

  @include breakpoint-tablet() {
    .title {
      column-gap: 25px;
    }
  }

  @include breakpoint-desktop() {
    .title {
      column-gap: 58px;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: $primary-gray-dark;
  }

  .items {
    @include fontSize(14px);
    line-height: 24px;
    padding: 0 16px 0 0;
    .divider {
      margin-top: 16px;
    }
  }

  @include breakpoint-tablet() {
    .items {
      padding: 0 80px 0 60px;
    }
  }

  @include breakpoint-desktop() {
    .items {
      padding: 0 32px 0 64px;
    }
  }

  .item {
    position: relative;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;

    div {
      position: relative;
    }

    @media print {
      padding-top: 2px;
    }
  }

  button {
    @extend %disclaimer-number;
    color: inherit;
    right: -12px;
  }

  .subitem {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;

    div {
      position: relative;
    }

    div:nth-child(even) {
      color: $gray-dark;
    }

    @media print {
      padding-top: 1px;
    }
  }

  .bold {
    font-family: $font-family-semibold;
  }

  .sublist {
    padding-left: 24px;
  }

  @include breakpoint-tablet() {
    .sublist {
      padding-left: 48px;
    }
  }

  .carret {
    @extend %carret;
    right: -15px;
    cursor: pointer;
  }

  @include breakpoint-tablet() {
    right: -25px;
  }

  .carretDown {
    transform: translateY(calc(-50% + 5px)) rotate(45deg);

    @media print {
      display: none;
    }
  }

  .carretUp {
    transform: translateY(calc(-50% + 10px)) rotate(225deg);

    @media print {
      display: none;
    }
  }

  .disclaimerWrapper {
    @include fontSize(10px);
    line-height: 1.6;
  }
}
