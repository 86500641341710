@import 'src/styles/components_importer';

.overlay {
  opacity: 0.5;
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

.offerDetailsModal {
  max-width: 900px;
  z-index: 1050;
  background-color: $white;
  margin-bottom: 30px;
  visibility: hidden;

  &.open {
    padding: 10px;
    transition: transform 0.3s ease-out;
    visibility: visible;
    display: inline-block;
  }

  .modalBody {
    .bodyLeft {
      width: 40%;
      display: inline-block;
      float: left;

      p {
        font-size: 0.875rem;
        color: $gray-dark;
        line-height: 1.4;
        margin: 10px 0 25px 0;
      }
    }
    .bodyRight {
      width: 60%;
      display: inline-block;
      float: right;
      text-align: right;

      .expDate {
        font-size: 0.875rem;
        padding-top: 20px;
        span {
          color: $red;
        }
      }

      .offerDetailImg {
        height: 100%;
        margin-top: 40px;
        margin-right: 42px;
      }
    }
  }

  .offerDisclaimer {
    float: left;
    font-size: 0.75rem;
    color: $gray-dark;
    line-height: 1.3;
  }

  .buttonWrapper {
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;

    .btn {
      padding: 12px;
      width: 250px;
      @include fontSize(16px);
    }
  }
}

@include breakpoint-tablet-only() {
  .offerDetailsModal {
    width: 600px;

    .modalBody {
      display: flex;
      flex-flow: column-reverse;

      .bodyLeft {
        width: 100%;
        p {
          margin-top: 30px;
        }
      }
      .buttonWrapper {
        .btn {
          margin-left: auto;
          margin-right: auto;
          padding: 11px 12px;
          width: 70%;
        }
      }
      .bodyRight {
        text-align: center;
        display: block;
        display: flex;
        flex-flow: column-reverse;
        height: 100%;
        width: 100%;

        .offerDetailImg {
          width: 380px;
          height: 100%;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
        }
      }
      .offerDisclaimer {
        margin-top: 25px;
      }
    }
  }
}

@include breakpoint-mobile-only() {
  .offerDetailsModal {
    width: 100%;
    height: auto;

    .modalBody {
      display: flex;
      flex-flow: column-reverse;

      .bodyLeft {
        width: 100%;
        p {
          margin-top: 30px;
        }
      }
      .buttonWrapper {
        .btn {
          margin-left: auto;
          margin-right: auto;
          padding: 11px 12px;
          width: 70%;
        }
      }
      .bodyRight {
        text-align: center;
        display: block;
        display: flex;
        flex-flow: column-reverse;
        height: 100%;
        width: 100%;

        .offerDetailImg {
          width: 380px;
          height: 100%;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
        }
      }
      .offerDisclaimer {
        margin-top: 25px;
      }
    }
  }
}

.modalHeader {
  padding: 15px;
  .closeModal {
    text-align: right;
  }
  .closeButton {
    margin-top: -2px;
    @include fontSize(24px);
    font-weight: 700;
    line-height: 1;
    color: $red;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }
  .closeSpan::before {
    content: '\00D7';
  }
  .title {
    text-align: center;
    @include fontSize(34px);
    font-family: $font-family-semibold;
  }
}
