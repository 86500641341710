@import 'src/styles/components_importer';

.modalBody {
  padding: 0 32px;
  .title {
    margin-top: 40px;
    margin-bottom: 32px;
    text-align: center;
    font-family: $default-font-family;
    @include fontSize(24px);
    font-weight: 600;
    color: $true-black;
  }
}

.raqForm {
  height: -webkit-fill-available;
  overflow: auto;
}

.financeTitle {
  display: block;
  @include fontSize(12px);
  font-weight: 100;
}

.label {
  display: flex;
  color: $true-black;
  @include fontSize(14px);
  margin-top: 15px;
  margin-bottom: 15px;
}

.contactMeByLabel {
  margin-bottom: 0;
}

.radio {
  position: relative;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: 1px solid $primary-gray-dark;
  background: $white;
  margin-top: 0px;
  border-radius: 55%;
  top: 10px;

  &::before {
    content: ' ';
    position: absolute;
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
    transition: all 0.1s;
    background: $white;
  }

  &:checked {
    background: $primary-red;
    border: none;

    &::before {
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border-radius: 55%;
    }
  }
}

.contactOption {
  margin-left: 5px;
  @include fontSize(12px);
  display: inline-block;
  width: 37px;
}

.preferenceContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  span {
    margin-right: 10px;
  }
}

.langPrefContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: sticky;

  &::after {
    content: '';
    position: absolute;
    top: 18px;
    right: 15px;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid $primary-red;
    border-right: 2px solid $primary-red;
    transform: rotate(134deg);
  }
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.commentsWrapper {
  padding-top: 10px;
  height: auto;
  @include fontSize(12px);

  label {
    @include fontSize(14px);
    margin-bottom: 15px;
  }

  textarea {
    width: 100%;
    outline: none;
    resize: none;
    -webkit-appearance: none;
    border-radius: 0px;
    border: 1px solid $primary-gray-dark;
    box-sizing: border-box;
    background-color: $white;
  }

  .staticComment {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    overflow: hidden;
  }

  .comment {
    height: 57px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.quoteCta {
  height: 75px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.disclaimer {
  margin-top: 30px;
  @include fontSize(9px);
  text-align: center;

  .disclaimerLink {
    color: #d71921;
  }
}
