@import 'src/styles/components_importer';

@include print(tradeInCell);

.root {
  flex-basis: 50%;
  padding: 8px 0 13px 0;
  position: relative;
  .row {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 27px;
  }
}

@include breakpoint-tablet {
  .root {
    .row {
      row-gap: 22px;
    }
    padding: 8px 0 0 0;
  }
}

.tradeInCtaWrapper {
  position: relative;
  padding: 10px 0 16px 0;
}

@include breakpoint-tablet {
  .tradeInCtaWrapper {
    padding: 0 0 10px 0;
  }
}

.tradeInWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45%;
}

@include breakpoint-tablet {
  .tradeInWrapper {
    position: relative;
    width: auto;
  }
}

.divider {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: $primary-gray-dark;
  bottom: -20px;
}

@include breakpoint-tablet {
  .divider {
    display: none;
  }
}
