@import 'src/styles/components_importer';

.headerWrapper {
  background-color: $background-color;
  padding: 40px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 100%;
  }

  @media print {
    padding: 5px 20px 0 20px !important;
  }
}

@include breakpoint-tablet() {
  .headerWrapper {
    flex-direction: row;
    padding: 32px 16px 0 16px;
  }
}

@include breakpoint-desktop() {
  .headerWrapper {
    padding: 40px 48px 0 48px;
  }
}

.title {
  @include fontSize(32px);
  line-height: 40px;
  font-family: $font-family-semibold;
  padding-bottom: 32px;
  width: 90%;
}

@include breakpoint-tablet() {
  .title {
    width: 100%;
    padding-bottom: 24px;
  }
}

@include breakpoint-desktop() {
  .title {
    @include fontSize(40px);
    line-height: 48px;
  }
}

.vin {
  @include fontSize(12px);
  line-height: 16px;
  padding-bottom: 4px;
}

.subtitle {
  @include fontSize(20px);
  line-height: 28px;
  font-family: $font-family-semibold;
  padding-bottom: 16px;
  max-width: 300px;
}

.bodyWrapper {
  padding: 30px 15px 40px 15px;
}

@include breakpoint-tablet() {
  .bodyWrapper {
    padding: 30px 20px 40px 20px;
  }
}

@include breakpoint-desktop() {
  .bodyWrapper {
    padding: 29px 110px 110px 48px;
  }

  @media print {
    .bodyWrapper {
      padding: 10px 20px 20px 20px;
    }
  }
}

.paymentDetails {
  display: flex;
  flex-direction: column;
  @include fontSize(32px);
  line-height: 40px;

  div {
    display: flex;
  }

  .sub {
    display: flex;
    position: relative;
    height: fit-content;
    width: fit-content;
    align-self: flex-end;
    @include fontSize(16px);
    line-height: 28px;
    padding-right: 20px;

    button {
      @extend %disclaimer-number;
      right: 12px;
      color: inherit;
    }
  }
}

@include breakpoint-mobile-only() {
  .paymentDetailsItem {
    @include fontSize(16px);
    line-height: 28px;
    div {
      align-items: flex-end;
    }
    .sub {
      align-items: flex-end;
    }
  }
}

@include breakpoint-tablet() {
  .paymentDetails {
    flex-direction: row;
  }
}

@include breakpoint-desktop() {
  .paymentDetails {
    @include fontSize(40px);
    line-height: 48px;
  }

  @media print {
    .paymentDetails {
      @include fontSize(26px);
      line-height: 32px;
    }
  }
}

.closeButton {
  @include fontSize(18px);

  position: absolute;
  top: 16px;
  right: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  width: 40px;
  height: 40px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid $gray;
  border-radius: 20px;
}

@include breakpoint-tablet() {
  position: initial;
  top: 0;
  right: 0;
}

.divider {
  height: 14px;
  width: 1px;
  background-color: $primary-gray-dark;
  align-self: center;
}

.downPaymentWrapper {
  display: flex;
  @include fontSize(10px);
  line-height: 16px;
  align-items: center;
  padding-left: 24px;
  font-family: $font-family-semibold;
  text-transform: lowercase;
  column-gap: 5px;
}

.dueAtSigningWrapper {
  color: $primary-red;
}

.mileageAndDepositWrapper {
  display: flex;
  column-gap: 16px;
  padding-top: 16px;

  div {
    @include fontSize(10px);
    position: relative;
    line-height: 16px;
    font-family: $font-family-semibold;
    text-transform: lowercase;
  }

  button {
    @extend %disclaimer-number;
    color: inherit;
  }
}

@include breakpoint-tablet() {
  .mileageAndDepositWrapper {
    div {
      @include fontSize(14px);
      line-height: 24px;
    }
  }

  @media print {
    .mileageAndDepositWrapper {
      padding: 8px 0;
    }
  }
}

@include breakpoint-tablet() {
  .downPaymentWrapper {
    @include fontSize(14px);
    line-height: 24px;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px 0 40px 0;

  @media print {
    display: none;
  }
}

@include breakpoint-tablet() {
  .buttonWrapper {
    padding: 24px 0;
    flex-direction: row;
    row-gap: 0;
  }
}

.btn {
  margin: 0;
  padding: 15px;
  width: 100%;
}

@include breakpoint-tablet() {
  .btn {
    width: auto;
  }
}

.disclaimerWrapper {
  @include fontSize(10px);
  line-height: 16px;
  padding-left: 24px;
}

.printBtn {
  &::after {
    content: none;
  }
}

.printWrapper {
  display: flex;
  column-gap: 12px;
  align-items: center;
  img {
    width: auto;
  }

  @media print {
    display: none;
  }
}
