@import 'src//styles/components_importer';

.modal {
  position: static;
  z-index: $modal-index;
  overflow: hidden;
  outline: 0;
}

.initialPosition {
  position: initial;
}

.modalContainer {
  width: 100%;
  height: 100%;
}

.modalDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.overlay {
  position: fixed;
  z-index: $modal-overlay-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: $black;
}

.darkOverlay {
  opacity: 1;

  @media print {
    background-color: $white;
  }
}

.dialog {
  display: inline-block;
  z-index: $modal-index;
  width: fit-content;
  position: absolute;
  top: 40px;

  @media print {
    top: 0;
  }

  &:focus {
    outline: none;
  }
}

@include breakpoint-mobile-only() {
  .dialog {
    top: 0;
  }
}

.closeBtnWrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.closeBtn {
  @include fontSize(24px);
  position: absolute;
  top: 10px;
  right: 15px;
  border: 0;
  outline: 0;
  background: none;
  color: $primary-red;
  font-weight: $font-family-semibold;
  cursor: pointer;

  &::after {
    content: '\00D7';
  }
}

.content {
  position: relative;
  max-height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  outline: 0;
  background-clip: padding-box;
  background-color: $white;
  box-shadow: 0 5px 15px rgba($black, 0.5);
  overflow: auto;
  margin-bottom: 20px;

  @media print {
    box-shadow: none;
  }
}
