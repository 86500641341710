@import 'src/styles/components_importer';

.heading {
  flex-grow: 1;
  text-align: center;

  .text {
    @include fontSize(24px);
    line-height: 32px;
    display: inline-block;
    font-family: $font-family-semibold;
  }
  .series {
    display: none;
    border-left: 1px solid #d3d3d3;
    margin-left: 15px;
    padding-left: 15px;
  }

  .smallText {
    @include fontSize(20px);
    line-height: 28px;
  }
}

@include breakpoint-tablet {
  .heading {
    text-align: left;
  }
}
