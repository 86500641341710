@import 'src/styles/components_importer';

.modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  transition: 0.2s cubic-bezier(0.3, 0.38, 0.15, 1);
  z-index: 99;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1030;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 100%;

  @media screen and (min-width: $tablet-breakpoint) {
    padding: 40px 16px;
  }
}

.content {
  flex: 1 1 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  outline: 0;
  border-radius: 0;
  z-index: 1040;
  background-color: $background-color;
  width: 100%;
  overflow-y: auto;

  @media screen and (min-width: $tablet-breakpoint) {
    flex: 0 1 auto;
    width: 734px;
  }

  @media screen and (min-width: $desktop-breakpoint) {
    width: 800px;
  }
}

.header {
  box-sizing: border-box;
  border: 1px solid #cdd1d4;
  border-radius: 4px 4px 0 0;
  background-color: #f4f7fb;
  padding: 0 24px;
}

.headerContent {
  display: flex;
  align-items: center;
  position: relative;
  height: 67px;
}

.headerText {
  color: #152935;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.22px;
  line-height: 21px;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    @include fontSize(24px);
    font-weight: 700;
    line-height: 1;
    color: $red;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    border: 0;
    background-color: transparent;

    &::after {
      content: '\00D7';
    }
  }
}

.footer {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  // background-color: $white;
}

.body {
  padding: 24px;
  max-width: 400px;
  background-color: #fff;
}
