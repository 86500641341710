/*
 * Media query mixins
 */

/*
 * Add media queries easily
 *
 * @param $breakpoint entire literal that defines the query, including parenthesis (eg. (min-width:$tablet) )
 */
@mixin media($breakpoint) {
  @media only screen and #{$breakpoint} {
    @content;
  }
}

/*
	Simple add print media to content
 */
@mixin print {
  @media print {
    @content;
  }
}
