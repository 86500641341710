@import 'src/styles/components_importer';

.subheaderWrapper {
  font-size: 10px;
  line-height: 18px;
  display: flex;
  position: relative;
  padding-bottom: 20px;

  p {
    position: relative;
    margin-right: 12px;
  }

  button {
    @extend %disclaimer-number;
  }
}
