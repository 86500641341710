@import 'src/styles/components_importer';
@import 'src/styles/layout/defaults';

.vsBody {
  @extend %wrapper;
  @include outer-container(979px);
}

.sup {
  @extend %disclaimer-number;
}

.categorySection {
  padding: 0;
  padding-top: 25px;
  border-top: 1px solid $gray-light;
  font-size: 100%;
  vertical-align: baseline;
}

.categoryTitle {
  margin-bottom: 30px;
  text-align: left;
  @include fontSize(24px);
  &::after {
    content: '';
    display: block;
    width: 79px;
    height: 2px;
    position: relative;
    top: 10px;
    background-color: #d71921;
  }
}

.vehicleCardList {
  @include clearfix();
  margin-bottom: 30px;

  .vehicleCardListElement {
    @include make-xs-column(12);
    transition: background-color 0.3s;
    &:hover {
      background-color: white;
    }
  }
}

.vehicleCard {
  display: table;
  width: 100%;
  color: black;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;

  outline: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
    color: black;
  }
  &:focus {
    outline: 1px solid $color-blue;
  }

  // Car Jelly/Series Year common attr
  .carJelly,
  .cardInfo {
    @include make-xs-column(6);
    display: table-cell;
    float: none;
    vertical-align: middle;
  }

  // Car Jelly
  .carJelly {
    text-align: center;
    .carJellyImg {
      height: 70px;
    }
  }

  // Card Info
  .cardInfo {
    height: 58px; // adds space for bottom for series with long names
    text-align: left;

    &.bold {
      font-family: $font-family-md;
      font-weight: 500;
    }

    & .msrp {
      @include fontSize(12px);
      color: $gray-dark;
      margin-top: 5px;

      span {
        position: relative;
      }
    }
  }
}

// Breakpoints
@include breakpoint-mobile-only() {
  .vehicleCardList {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@include breakpoint-tablet() {
  .vsBody {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .vehicleCardListElement {
    @include make-xs-column(4);
  }

  .carJelly,
  .cardInfo {
    @include make-xs-column(12);
    display: block;
    float: left;

    .carJellyImg {
      height: 90px;
    }

    .seriesName {
      @include fontSize(22px);
      text-align: center;
      font-weight: 300;
    }

    .msrp {
      display: block;
      text-align: center;
      margin: 8px 0;
    }
  }
}

@include breakpoint-desktop() {
  .vehicleCardListElement {
    @include make-xs-column(3);
  }
}
