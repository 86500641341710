
.pe-loading { position: absolute; width: 100%; height: 100%; background: #edf0f5; }
.pe-loading-content { position: absolute; top: 50%; left: 50%; margin: -110px 0 0 -110px; }
.pe-loading-text { position: relative; display:inline-block; top: -27px; color: #333; }

/* Loading animation css-only */
.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.loader {
  display: inline-block;
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 1);
  border-right: 0.5em solid rgba(255, 255, 255, 1);
  border-bottom: 0.5em solid rgba(255, 255, 255, 1);
  border-left: 0.5em solid #333;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

