//TODO: delete breakpoint-* mixins

@mixin breakpoint-tablet {
  :global(.bp-tablet),
  :global(.bp-desktop) {
    @content;
  }
}

@mixin breakpoint-desktop() {
  :global(.bp-desktop) {
    @content;
  }
}

@mixin breakpoint-mobile-only() {
  :global(.bp-mobile) {
    @content;
  }
}

@mixin breakpoint-tablet-only() {
  :global(.bp-tablet) {
    @content;
  }
}

@mixin breakpoint-tablet-print() {
  @include breakpoint-tablet() {
    @content;
  }
  @media print {
    @content;
  }
}

@mixin breakpoint-desktop-print() {
  @include breakpoint-desktop() {
    @content;
  }
  @media print {
    @content;
  }
}

@mixin print($className) {
  @media print {
    .#{$className} {
      display: none;
    }
  }
}
