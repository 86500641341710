@import 'src/styles/components_importer';
@import 'src/styles/layout/defaults';

.text {
  display: inline-block;
  @include fontSize(30px);
  font-family: $font-family-semibold;

  &-2 {
    border-left: 1px solid $gray-light;
    margin-left: 15px;
    padding-left: 15px;
  }
  i {
    font-style: italic;
  }
}

.header {
  text-align: center;
  margin-bottom: 28px;
  padding-top: 10px;

  .inline .text {
    display: inline-block;
  }
}

.subText {
  @include fontSize(13px);
  color: $gray-dark;
  margin-top: 8px;

  @include media($desktop) {
    @include fontSize(16px);
  }
}

@include breakpoint-tablet() {
  .text {
    @include fontSize(32px);
  }
}

@include breakpoint-desktop() {
  .text {
    @include fontSize(34px);
  }
  .subText {
    @include fontSize(16px);
  }
}
